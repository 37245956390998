<template>
    <section class="obras-vigentes-ver-cotizaciones container-fluid overflow-auto scroll-none">
        <titulo-divisor titulo="Materiales">
            <div class="row">
                <div class="col auto">
                    <el-input v-model="filter.buscar" placeholder="Buscar cotización" clearable size="small" @keypress.native.enter="getMaterialsList()" @clear="Action_limpiar_filtros(); getMaterialsList()" />
                </div>
                <div v-if="!contar" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-else class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2">
                            <p class="f-12"> {{ contar }} </p>
                            <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="limpiarFiltro" />
                        </div>
                    </div>
                </div>
            </div>
        </titulo-divisor>
        <!-- tabla -->
        <div v-if="materialsList" class="row justify-content-center">
            <div class="col-11 shadow29 border br-4 px-0">
                <el-table :data="materialsList.data" style="width: 100%" max-height="450">
                    <el-table-column fixed prop="nombre" label="Nombre" width="300">
                        <template slot-scope="scope">
                            <p class="tres-puntos">{{ scope.row.nombre }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column fixed prop="type" label="Tipo" width="115">
                        <template slot-scope="scope">
                            <p class="tres-puntos">{{ scope.row.tipo_material }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column fixed align="center" prop="total_unidades" width="160" label="Total de unidades">
                        <template slot-scope="scope">
                            {{ scope.row.total_unidades ? scope.row.total_unidades : 0 }}
                        </template>
                    </el-table-column>
                    <div v-for="(data, idx) in onlyProviders" :key="idx">
                        <el-table-column align="center" width="auto">
                            <template slot="header">
                                <p class="tres-puntos">{{ data.nombre }}</p>
                            </template>
                            <template slot-scope="scope">
                                <el-tooltip effect="light" visible-arrow>
                                    <div slot="content">
                                        <div >
                                            <p>Valor unitario: {{ formatoMoneda(valorUnitarioProvider(scope.row.proveedores,data)) }} </p>
                                            <p class="my-1">Valor total: {{ formatoMoneda(valorTotalProvider(scope.row.proveedores,data)) }}</p>
                                            <p>Cotizacion: {{ valorNumeroProvider(scope.row.proveedores, data) }} </p>
                                        </div>
                                    </div>
                                    <p class="text-center">{{ validateProvider(scope.row.proveedores, data) }}</p>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </div>
                </el-table>
            </div>
            <div class="col-auto mt-3">
                <pagination :pagination="materialsList" @paginate="getMaterialsList"></pagination>
            </div>
        </div>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-3">
                    <p class="input-label-top">Proveedor</p>
                    <el-select v-model="filter.id_proveedor" placeholder="Seleccionar proveedor" size="small" class="w-100" clearable>
                        <el-option v-for="item in select_providers" :key="item.id" :label="item.proveedor" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <el-checkbox v-model="filter.materiales_no_cotizados" label="Materiales no cotizados" class="d-flex mb-2" />
                    <el-checkbox v-model="filter.materiales_cotizados" label="Materiales cotizados" class="d-flex mb-2" />
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Tipo de material</p>
                    <el-select v-model="filter.id_tipo_material" placeholder="Seleccionar material" size="small" class="w-100" clearable>
                        <el-option v-for="item in select_material_types" :key="item.id" :label="item.tipo_material" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="f-12 mb-3">Ordenar proveedor por:</p>
                    <div>
                        <el-radio-group v-model="filter.ordena_proveedor_por">
                            <el-radio label="1" class="d-flex mb-2">Nombre</el-radio>
                            <el-radio label="2" class="d-flex mb-2">Fecha de cotización</el-radio>
                            <el-radio label="3" class="d-flex mb-2">Mayor número de materiales cotizados</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    name: 'ListadoCotizacionesProyecto',
    data(){
        return{
            buscarListado: '',
            valueSelect: '',
            cantFiltros: 0,
            checkEstados: [],
            radioTipoRevision: '1',
            onlyProviders: [],
        }
    },
    computed: {
        ...mapGetters({
            select_providers: 'selects/selects/select_providers',
            select_material_types: 'selects/selects/select_material_types',
            materialsList: 'obras/cotizaciones/materialsList',
            filtros: 'obras/cotizaciones/filters',
        }),
        filter:{
            get(){ return this.filtros },
            set(val){ this.set_filters(val) }
        },
        contar(){
            return this.filtros.count();
        }
    },
    async mounted () {
        this.filtros.id_obra = this.$route.params.id
        await this.Action_get_select_providers();
        await this.Action_get_select_material_types();
        await this.getMaterialsList()
    },
    methods:{
        ...mapActions({
            Action_get_select_providers: 'selects/selects/Action_get_select_providers',
            Action_get_select_material_types: 'selects/selects/Action_get_select_material_types',
            Action_get_materials_cotizados: 'obras/cotizaciones/Action_get_materials',
            Action_limpiar_filtros: 'obras/cotizaciones/Action_limpiar_filtros',
        }),
         ...mapMutations({
            set_filters: 'obtras/cotizaciones/set_filters'
        }),
        valorNumeroProvider(proveedores, data){
            let idxOf = proveedores.findIndex(e => e.id_proveedor == data.id_proveedor)
            if (idxOf != -1) {
                return proveedores[idxOf].numero
            } else {
                return ''
            }
        },
        valorTotalProvider(proveedores, data){
            let idxOf = proveedores.findIndex(e => e.id_proveedor == data.id_proveedor)
            if (idxOf != -1) {
                return (proveedores[idxOf].valor * proveedores[idxOf].cantidad)
            } else {
                return ''
            }
        },
        valorUnitarioProvider(proveedores, data){
            let idxOf = proveedores.findIndex(e => e.id_proveedor == data.id_proveedor)
            if (idxOf != -1) {
                return proveedores[idxOf].valor
            } else {
                return ''
            }
        },
        validateProvider(proveedores, data){
            let idxOf = proveedores.findIndex(e => e.id_proveedor == data.id_proveedor)
            if (idxOf != -1) {
                return proveedores[idxOf].cantidad
            }
        },
        getOnlyProviders(){
            this.materialsList.data.forEach(element => {
                element.proveedores.forEach(e => {
                    this.onlyProviders.push(e)
                });
            });
            let hash = {};
            this.onlyProviders = this.onlyProviders.filter(o => hash[o.id] ? false : hash[o.id] = true);
        },
        async getMaterialsList(page=1){
            await this.Action_get_materials_cotizados({page,id_obra:this.$route.params.id});
            this.getOnlyProviders()
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        async filtrarMateriales(){
            if (this.filtros) {
                this.filtros.materiales_cotizados = this.filtros.materiales_cotizados ? 1 : 0
                this.filtros.materiales_no_cotizados = this.filtros.materiales_no_cotizados ? 1 : 0
            }
            await this.getMaterialsList()
            this.$refs.abrirModalFiltros.toggle()
        },
        async limpiarFiltro2(){
            await this.getMaterialsList()
            this.$refs.abrirModalFiltros.toggle()

        },
        async limpiarFiltro(){
            await this.Action_limpiar_filtros()
            await this.getMaterialsList()
            this.$refs.abrirModalFiltros.toggle()
            //await this.listar()
        },
    }
}
</script>

<style lang="scss" scoped>
.obras-vigentes-ver-cotizaciones{
    height: calc(100vh - 415px);
}
</style>